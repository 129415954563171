import theme from '../theme';
import { BreakPoints, ExecutionContext } from 'styled-components';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/string';
import { pipe } from 'fp-ts/function';

export const mediaMin = pipe(
  theme.breakpoints,
  R.reduceWithIndex(S.Ord)(
    {} as Readonly<Record<Extract<keyof BreakPoints, string>, (props: ExecutionContext) => string>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ExecutionContext) => `min-width: ${props.theme.breakpoints[key] + 1}px`,
    }),
  ),
);

export const mediaMax = pipe(
  theme.breakpoints,
  R.reduceWithIndex(S.Ord)(
    {} as Readonly<Record<Extract<keyof BreakPoints, string>, (props: ExecutionContext) => string>>,
    (key, acc) => ({
      ...acc,
      [key]: (props: ExecutionContext) => `max-width: ${props.theme.breakpoints[key]}px`,
    }),
  ),
);
