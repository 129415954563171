import React, { FC } from 'react';

import * as Styled from './NotFound.styles';
import { Button, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const NotFound: FC = () => (
  <Styled.NotFoundContainer>
    <Header size="huge" as="h1">
      Page non trouvée.
    </Header>

    <Button primary as={Link} to="/" size="large">
      Revenir à l'accueil
    </Button>
  </Styled.NotFoundContainer>
);

export default NotFound;

export const NotFoundSpace: FC = () => {
  return (
    <Styled.NotFoundContainer>
      <Header size="huge" as="h1">
        Espace non trouvé
      </Header>
    </Styled.NotFoundContainer>
  );
};

export const TechnicalError: FC = () => (
  <Styled.NotFoundContainer>
    <Header size="huge" as="h1">
      Erreur interne
    </Header>
  </Styled.NotFoundContainer>
);
