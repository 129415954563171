import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { TRANSACTION_ID_HEADER_KEY } from '.';
import { camelToSnake } from '@shared/utils/string';
import { stringifyQueries } from '@shared/utils/queries';

function convertParamsToSnakeCase(params: { [key: string]: any }): { [key: string]: any } {
  return Object.entries(params)
    .map(([key, value]) => [camelToSnake(key), value])
    .reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]: value,
      };
    }, {});
}

function configureInterceptors() {
  axios.interceptors.request.use(config => {
    return {
      ...config,
      headers: config.headers.concat({ [TRANSACTION_ID_HEADER_KEY]: uuid() }),
      params: config.params ? convertParamsToSnakeCase(config.params) : undefined,
      withCredentials: true,
      paramsSerializer: { serialize: stringifyQueries },
    };
  });
}

export default function configureAxios() {
  configureInterceptors();
}
