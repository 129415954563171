import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import { ToastsStyles } from './ToastProvider.styles';

const ToastsProvider: FC = () => (
  <>
    <ToastsStyles />
    <ToastContainer toastClassName="citymag-toast" hideProgressBar={true} />
  </>
);

export default ToastsProvider;
