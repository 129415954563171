import { httpService, HttpTask } from '../../../core/http';

import { BackProfile } from './model';
import {
  AuthenticateParams,
  ForgotPasswordParams,
  PasswordParams,
  UpdateProfileParams,
  ChangePasswordParams,
  ChangeEmailParams,
} from '../../auth/model';
import { AuthContextProfile } from '../../auth/context';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { ApplicationSpace } from '../../../shared/utils/config';

export function getProfile(): HttpTask<BackProfile> {
  return httpService.get('/api/back/profile');
}

export function getProfileAsAuthContextProfile(): HttpTask<AuthContextProfile> {
  return pipe(
    getProfile(),
    TE.map<BackProfile, AuthContextProfile>(p => ({ ...p, space: ApplicationSpace.Back })),
  );
}

export function authenticate(params: AuthenticateParams): HttpTask<void> {
  return httpService.post('/api/back/authenticate', params);
}

export function logout(): HttpTask<void> {
  return httpService.post('/api/back/logout');
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask<void> {
  return httpService.post('/api/back/password-reset/forgot', params);
}

export function getResetPasswordDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/api/back/password-reset/${token}`);
}

export function resetPassword(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/api/back/password-reset/${token}`, params);
}

export function getActivationDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/api/back/activation/${token}`);
}

export function activateAccount(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/api/back/activation/${token}`, params);
}

export function updateProfile(params: UpdateProfileParams): HttpTask<void> {
  return httpService.post('/api/back/profile', params);
}

export function updateProfilePassword(params: ChangePasswordParams): HttpTask<void> {
  return httpService.post('/api/back/profile/password', params);
}

export function updateProfileEmail(params: ChangeEmailParams): HttpTask<void> {
  return httpService.post('/api/back/profile/email', params);
}
