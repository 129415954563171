import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpError, HttpTask, HttpStatusCode } from './model';

import * as TE from 'fp-ts/TaskEither';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { RangeList, RangeRequest, RangeResult } from '../../shared/modules/pagination';
import { history } from '../../App';
import { logSentryHttpError } from '../../shared/modules/sentry/utils';

const WHITE_LIST_REDIRECT = ['/authenticate', '/profile'];

const isWhiteList = (url: string) => WHITE_LIST_REDIRECT.filter(whiteUrl => url.includes(whiteUrl)).length > 0;

function onError<E = unknown>(err: unknown): HttpError<E> {
  const httpError = HttpError.fromAxiosError<E>(err as AxiosError);

  if (
    httpError.status >= 400 &&
    ![
      HttpStatusCode.UNAUTHORIZED,
      HttpStatusCode.FORBIDDEN,
      HttpStatusCode.NOT_FOUND,
      HttpStatusCode.CONFLICT,
    ].includes(httpError.status)
  ) {
    logSentryHttpError(
      `[http] error ${httpError.status} on ${O.getOrElse(() => 'unknown')(httpError.url)} path`,
      httpError,
      undefined,
      {
        err,
      },
    );
  }

  const redirectToLogin = pipe(
    httpError.url,
    O.exists(url => httpError.status === HttpStatusCode.UNAUTHORIZED && !isWhiteList(url)),
  );

  if (redirectToLogin) {
    history.push('/login', {
      referrer: history.location.pathname,
    });
  }

  return httpError;
}

function transformRequest<R, E>(
  request: () => Promise<AxiosResponse<R>>,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return pipe(
    TE.tryCatch(request, err => onError<E>(err)),
    TE.map(res => (raw ? res : res.data)),
  );
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function get<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function get<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return transformRequest(() => axios.get(url, config), raw);
}

function getRange<R extends Object, F extends Object, E = unknown>(
  url: string,
  request?: RangeRequest,
  config?: AxiosRequestConfig,
): HttpTask<RangeList<R, F>, E> {
  return pipe(
    get<RangeResult<R, F>, E>(url, {
      ...config,
      params: {
        ...(config ? config.params : {}),
        ...request,
      },
    }),
    TE.map(res => RangeList.fromRangeResult(res, request)),
  );
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E>;
function post<R = unknown, E = unknown>(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;

function post<R = unknown, E = unknown>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return transformRequest(() => axios.post(url, data, config), raw);
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E>;
function del<R = unknown, E = unknown>(
  url: string,
  config: AxiosRequestConfig,
  raw: true,
): HttpTask<AxiosResponse<R>, E>;
function del<R = unknown, E = unknown>(
  url: string,
  config?: AxiosRequestConfig,
  raw?: true,
): HttpTask<R | AxiosResponse<R>, E> {
  return transformRequest(() => axios.delete(url, config), raw);
}

export const httpService = {
  get,
  getRange,
  post,
  delete: del,
};
