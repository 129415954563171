import React from 'react';
import { createRoot } from 'react-dom/client';

import './styles';

import App from './App';

import configureAxios from './core/http/config';
import { configureSentry } from '@shared/modules/sentry/utils';

configureAxios();
configureSentry();

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(<App />);
}
