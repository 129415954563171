import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { filterEmptyStringToOption } from './string';

const buildEnv = import.meta.env;

export const getConfig = (): { [key: string]: string | undefined } => ({
  ...buildEnv,
  ...window._env_,
});

export const getConfigValue = (key: string): O.Option<string> =>
  pipe(
    O.fromNullable(getConfig()[key]),
    O.filterMap(val => (typeof val === 'string' ? filterEmptyStringToOption(val) : O.none)),
  );

export enum ApplicationSpace {
  Back = 'back',
  Operator = 'operator',
  Group = 'group',
}

const applicationSpaceConfigMatch: { [key in ApplicationSpace]: string } = {
  [ApplicationSpace.Back]: 'VITE_BACK_HOST',
  [ApplicationSpace.Operator]: 'VITE_OPERATOR_HOST',
  [ApplicationSpace.Group]: 'VITE_GROUP_HOST',
};

export function getApplicationSpace(): O.Option<ApplicationSpace> {
  const config = getConfig();

  return pipe(
    Object.values(ApplicationSpace),
    A.findFirst(type => config[applicationSpaceConfigMatch[type]] === window.location.hostname),
  );
}
