import { httpService, HttpTask } from '../../../core/http';
import { AuthenticateByAccessCodeParams, CreatePermanentAccountParams, OperatorProfile } from './model';
import {
  AuthenticateParams,
  ChangeEmailParams,
  ChangePasswordParams,
  ForgotPasswordParams,
  PasswordParams,
  UpdateProfileParams,
} from '../../auth/model';
import { AuthContextProfile } from '../../auth/context';
import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import { ApplicationSpace } from '../../../shared/utils/config';

export function getProfile(): HttpTask<OperatorProfile> {
  return httpService.get('/api/operator/profile');
}

export function getProfileAsAuthContextProfile(): HttpTask<AuthContextProfile> {
  return pipe(
    getProfile(),
    TE.map<OperatorProfile, AuthContextProfile>(p => ({ ...p, space: ApplicationSpace.Operator })),
  );
}

export function authenticate(params: AuthenticateParams): HttpTask<void> {
  return httpService.post('/api/operator/authenticate', params);
}

export function authenticateByAccessCode(params: AuthenticateByAccessCodeParams): HttpTask<void> {
  return httpService.post('/api/operator/authenticate/code', params);
}

export function logout(): HttpTask<void> {
  return httpService.post('/api/operator/logout');
}

export function createPermanentAccount(params: CreatePermanentAccountParams): HttpTask<void> {
  return httpService.post('/api/operator/account/activate', params);
}

export function deleteAccount(): HttpTask<void> {
  return httpService.delete('/api/operator/account');
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask<void> {
  return httpService.post('/api/operator/password-reset/forgot', params);
}

export function getResetPasswordDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/api/operator/password-reset/${token}`);
}

export function resetPassword(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/api/operator/password-reset/${token}`, params);
}

export function getActivationDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/api/operator/activation/${token}`);
}

export function activateAccount(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/api/operator/activation/${token}`, params);
}

export function updateProfile(params: UpdateProfileParams): HttpTask<void> {
  return httpService.post('/api/operator/profile', params);
}

export function updateProfilePassword(params: ChangePasswordParams): HttpTask<void> {
  return httpService.post('/api/operator/profile/password', params);
}

export function updateProfileEmail(params: ChangeEmailParams): HttpTask<void> {
  return httpService.post('/api/operator/profile/email', params);
}
