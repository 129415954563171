import styled, { css } from 'styled-components';
import { Dropdown, Input, Segment } from 'semantic-ui-react';
import { mediaMax, mediaMin } from '../../../../styles/utils/responsive';

export const FiltersContainer = styled(Segment)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 0 !important;
`;

export const FiltersContent = styled.div`
  display: flex;

  align-items: center;
  flex: 1 1 auto;
  padding-bottom: 15px;

  @media screen and (${mediaMax.tablet}) {
    flex-wrap: wrap;
    row-gap: 15px;
  }
`;

export const SearchContent = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1 350px;
  padding-bottom: 15px;
`;

export const FilterLabel = styled.p`
  width: 70px;
  min-width: 70px;
  margin: 0 10px 0 0;
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.6;

  @media screen and (${mediaMax.desktop}) {
    display: none;
  }
`;

export const AsyncFilterDropDownContainer = styled.div`
  display: flex;
  width: 100%;
  > div {
    width: 100%;
    max-width: 300px;
    margin-right: 15px;
  }
`;

export const FilterDropDown = styled(Dropdown)`
  width: 100%;
  ${props => (props.multiple ? `max-width: 400px;` : `max-width: 300px;`)}
  margin-right: 15px;

  ${props =>
    props.multiple &&
    css`
      input[type='text'] {
        height: auto;
      }
    `}
`;

export const DateRangeFilterContainer = styled.div`
  display: flex;

  p {
    display: none;
  }

  .date-range {
    display: flex;

    > div:last-child {
      margin-top: 0;
    }
  }
`;

export const SearchInput = styled(Input)`
  flex: 1 1 auto;
  margin-right: 15px;

  @media screen and (${mediaMin.tablet}) {
    max-width: 300px;
  }
`;

export const EraseContainer = styled.div`
  padding-left: 15px;
  border-left: 1px solid ${props => props.theme.colors.light};

  button {
    border-radius: 50% !important;
  }
`;
