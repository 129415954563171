import React, { FC, PropsWithChildren } from 'react';

import { useLocation } from 'react-router-dom';

import * as Styled from './Layout.styles';
import { useAuthContext } from '../modules/auth/context';

import * as O from 'fp-ts/Option';
import Nav from './nav/Nav';
import { pipe } from 'fp-ts/function';

import ToastsProvider from '../shared/components/toasts/ToastProvider';

const shouldHideNav = (pathname: string) =>
  pathname.includes('login') || pathname.includes('password-reset') || pathname.includes('activation');

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { handleLogout } = useAuthContext();

  const { profile } = useAuthContext();

  const hideNav = O.isNone(profile) || shouldHideNav(location.pathname);

  return (
    <Styled.LayoutContainer>
      {pipe(
        profile,
        O.filter(() => !hideNav),
        O.map(p => <Nav profile={p} onLogout={handleLogout} />),
        O.toNullable,
      )}
      <Styled.LayoutContent>{children}</Styled.LayoutContent>
      <ToastsProvider />
    </Styled.LayoutContainer>
  );
};

export default Layout;
