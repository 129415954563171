import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import { GlobalStyles } from './styles/global.styles';
import { Router } from 'react-router-dom';
import { AuthContextProvider } from './modules/auth/context';
import Layout from './layout/Layout';

import * as O from 'fp-ts/Option';
import { ApplicationSpace, getApplicationSpace } from './shared/utils/config';
import { pipe } from 'fp-ts/function';
import { NotFoundSpace } from './shared/components/not-found/NotFound';
import { createBrowserHistory } from 'history';

import BackRoutes from './modules/back/routes';
import OperatorRoutes from './modules/operator/routes';
import GroupRoutes from './modules/group/routes';

const applicationSpace = getApplicationSpace();

const routes: { [key in ApplicationSpace]: ReactNode } = {
  [ApplicationSpace.Back]: <BackRoutes />,
  [ApplicationSpace.Operator]: <OperatorRoutes />,
  [ApplicationSpace.Group]: <GroupRoutes />,
};

export const history = createBrowserHistory();

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Router history={history}>
      {pipe(
        applicationSpace,
        O.fold(
          () => <NotFoundSpace />,
          space => (
            <AuthContextProvider space={space}>
              <Layout>{routes[space]}</Layout>
            </AuthContextProvider>
          ),
        ),
      )}
    </Router>
  </ThemeProvider>
);

export default App;
