import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useGroupAuthContext } from '../auth/context';
import * as O from 'fp-ts/Option';
import { Routes, safeLazy } from '../../core/router';

export interface GroupRouteProps {
  restricted?: boolean;
}

export const GroupRoute: FC<GroupRouteProps & RouteProps> = ({ restricted, ...routeProps }) => {
  const { profile } = useGroupAuthContext();

  if (restricted && O.isNone(profile)) {
    return <Redirect to={{ pathname: '/login', state: { referrer: window.location.pathname } }} />;
  }

  return <Route {...routeProps} />;
};

const GroupAuthRoutes = safeLazy(() => import('./auth/routes'));
const GroupReportsRoutes = safeLazy(() => import('./reports/routes'));

const GroupRoutes: FC = () => (
  <Routes>
    <Redirect path="/" to="/reports" exact />
    <GroupRoute path="/reports" component={GroupReportsRoutes} restricted />
    <GroupRoute path="/" component={GroupAuthRoutes} />
  </Routes>
);
export default GroupRoutes;
