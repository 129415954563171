import { EnumLabels } from '../../../shared/model/enum';

export enum InspectionTrackingState {
  ToDo = 'TODO',
  Done = 'DONE',
}

export const inspectionTrackingStateLabels: EnumLabels<InspectionTrackingState> = {
  [InspectionTrackingState.ToDo]: 'A traiter',
  [InspectionTrackingState.Done]: 'Terminé',
};

export interface InspectionTrackingRangeItem {
  id: string;
  date: string;
  extendedName: string;
  siteName: string;
  postalCode: string;
  city: string;
  state: InspectionTrackingState;
}
