import React, { FC, PropsWithChildren, Suspense } from 'react';
import { Route as ReactRoute, Switch } from 'react-router-dom';

import NotFound, { TechnicalError } from '../../shared/components/not-found/NotFound';
import LineLoader from '../../layout/loaders/line-loader/LineLoader';
import * as Sentry from '@sentry/react';

export const Routes: FC<PropsWithChildren> = ({ children }) => (
  <Sentry.ErrorBoundary fallback={<TechnicalError />}>
    <Suspense fallback={<LineLoader />}>
      <Switch>
        {children}
        <ReactRoute component={NotFound} />
      </Switch>
    </Suspense>
  </Sentry.ErrorBoundary>
);
