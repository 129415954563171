import { PasswordFormBody } from '../../auth/model';

export enum OperatorProfileType {
  Account = 'account',
  Code = 'code',
}

export interface OperatorAccountProfile {
  type: OperatorProfileType.Account;
  email: string;
  firstName: string | null;
  lastName: string | null;
  sites: Array<OperatorSite>;
}

export interface OperatorCodeProfile {
  type: OperatorProfileType.Code;
  code: string;
  name: string;
  inspectionId: string;
  site: OperatorSite;
  email: string;
  isActivated: boolean;
  canCreateAccount: boolean;
}

export interface OperatorSite {
  id: string;
  name: string;
  address: string | null;
  postalCode: string;
  city: string;
  siret: string;
  email: string | null;
}

export type OperatorProfile = OperatorAccountProfile | OperatorCodeProfile;

export interface AuthenticateByAccessCodeParams {
  code: string;
}

export interface CreatePermanentAccountParams {
  password: string;
  firstName: string | null;
  lastName: string | null;
}

export interface CreatePermanentAccountFormBody extends PasswordFormBody {
  firstName: string | null;
  lastName: string | null;
}
