import { createGlobalStyle } from 'styled-components';
import { mediaMax } from './utils/responsive';

export const GlobalStyles = createGlobalStyle`
  #root {
    position: relative;
    height: 100%;
    overflow: hidden;
  }
  
  :-moz-focusring {
    outline: none;
  }

  @media screen and (${mediaMax.desktop}) {
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px !important;
    }
  };
`;
