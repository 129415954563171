import { ExecutionContext, Color } from 'styled-components';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

const DEFAULT_PRIMARY: Extract<keyof Color, number> = 600;
const DEFAULT_SECONDARY: Extract<keyof Color, number> = 500;
const DEFAULT_TERTIARY: Extract<keyof Color, number> = 700;

function getColor(color: Color, defaultKey: Extract<keyof Color, number>, key?: Extract<keyof Color, number>): string {
  return pipe(
    O.fromNullable(key),
    O.fold(
      () => color[defaultKey],
      k => color[k],
    ),
  );
}

export function primaryColor(key?: Extract<keyof Color, number>): (props: ExecutionContext) => string {
  return ({ theme }) => getColor(theme.colors.primary, DEFAULT_PRIMARY, key);
}

export function secondaryColor(key?: Extract<keyof Color, number>): (props: ExecutionContext) => string {
  return ({ theme }) => getColor(theme.colors.secondary, DEFAULT_SECONDARY, key);
}

export function tertiaryColor(key?: Extract<keyof Color, number>): (props: ExecutionContext) => string {
  return ({ theme }) => getColor(theme.colors.tertiary, DEFAULT_TERTIARY, key);
}
