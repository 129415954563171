import styled, { keyframes } from 'styled-components';

import { primaryColor } from '../../../styles/utils';

const loadingAnimation = keyframes`
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
`;

export const Loader = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 100%;
  overflow: hidden;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: ${primaryColor()};
    animation: ${loadingAnimation} 2s linear infinite;
  }
`;
