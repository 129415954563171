import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import * as Styled from './Nav.styles';

import logo from '../../assets/logos/logo.svg';
import { OperatorProfileType } from '../../modules/operator/auth/model';
import { InspectionTrackingState } from '../../modules/back/inspections-tracking/model';
import { AuthContextProfile } from '../../modules/auth/context';
import { ApplicationSpace } from '../../shared/utils/config';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { sequenceT } from 'fp-ts/Apply';

interface NavEntry {
  title: string;
  link: string;
}

function getNullableName(firstName: string | null, lastName: string | null, fallback: string): string {
  return pipe(
    sequenceT(O.Apply)(O.fromNullable(firstName), O.fromNullable(lastName)),
    O.fold(
      () => fallback,
      ([firstName, lastName]) => `${firstName} ${lastName}`,
    ),
  );
}

function getProfileName(profile: AuthContextProfile): string {
  switch (profile.space) {
    case ApplicationSpace.Back:
      return `${profile.firstName} ${profile.lastName}`;
    case ApplicationSpace.Operator:
      if (profile.type === OperatorProfileType.Code) {
        return profile.name;
      } else {
        return getNullableName(profile.firstName, profile.lastName, 'Opérateur');
      }
    case ApplicationSpace.Group:
      return getNullableName(profile.firstName, profile.lastName, 'Client collectif');
  }
}

function getNavEntries(profile: AuthContextProfile): Array<NavEntry> {
  switch (profile.space) {
    case ApplicationSpace.Back:
      return [
        {
          title: 'Configurations de grille',
          link: '/grid-templates',
        },
        {
          title: 'Documents de contrôle',
          link: '/grid-documents',
        },
        {
          title: 'Editeur de grilles',
          link: '/grids',
        },
        {
          title: 'Documents opérateurs',
          link: '/operators',
        },
        {
          title: 'Suivi des visites sans email',
          link: `/inspections-tracking?state=${InspectionTrackingState.ToDo}`,
        },
        {
          title: 'Clients collectifs',
          link: '/groups',
        },
        {
          title: 'Auditeurs',
          link: '/auditors',
        },
        {
          title: 'Administrateurs',
          link: '/users',
        },
        {
          title: 'Erratum sur rapport',
          link: '/erratum',
        },
        {
          title: 'Renvoi de rapport',
          link: '/reports',
        },
        {
          title: 'Historique des synchronisations',
          link: '/syncs',
        },
      ];
    case ApplicationSpace.Operator:
      if (profile.type === OperatorProfileType.Account) {
        return [
          {
            title: 'Mes visites',
            link: '/inspections',
          },
          {
            title: 'Mes documents',
            link: '/documents',
          },
        ];
      } else {
        return [
          {
            title: 'Ma visite',
            link: `/inspections/${profile.inspectionId}`,
          },
        ];
      }
    case ApplicationSpace.Group:
      return [
        {
          title: 'Mes rapports',
          link: '/reports',
        },
      ];
    default:
      return [];
  }
}

interface NavProps {
  profile: AuthContextProfile;
  onLogout: () => void;
}

const Nav: FC<NavProps> = ({ profile, onLogout }) => {
  const location = useLocation();

  const navRef = useRef<HTMLDivElement>(null);
  const burgerRef = useRef<HTMLDivElement>(null);

  const [navOpen, setNavOpen] = useState<boolean>(false);

  const toggleNav = () => setNavOpen(old => !old);

  const entries = useMemo(() => getNavEntries(profile), [profile]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        navRef.current &&
        burgerRef.current &&
        e.target instanceof Element &&
        !navRef.current.contains(e.target) &&
        !burgerRef.current.contains(e.target)
      ) {
        setNavOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setNavOpen(false);
  }, [location.pathname]);

  const cannotCreateAccount =
    profile.space === 'operator' && profile.type === OperatorProfileType.Code && !profile.canCreateAccount;

  return (
    <>
      <Styled.NavBurger ref={burgerRef} $isOpen={navOpen} onClick={toggleNav}>
        <span />
      </Styled.NavBurger>
      <Styled.NavContainer ref={navRef} $isOpen={navOpen}>
        <Styled.NavHeader>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </Styled.NavHeader>
        <Styled.NavContent>
          <Styled.NavEntries>
            {entries.map((entry, j) => (
              <li key={j}>
                <NavLink to={entry.link} activeClassName="active">
                  {entry.title}
                </NavLink>
              </li>
            ))}
          </Styled.NavEntries>
        </Styled.NavContent>
        <Styled.NavFooter>
          {!cannotCreateAccount ? (
            <Link to="/profile">{getProfileName(profile)}</Link>
          ) : (
            <p>{getProfileName(profile)}</p>
          )}
          <Styled.LogoutButton onClick={onLogout} />
        </Styled.NavFooter>
      </Styled.NavContainer>
    </>
  );
};

export default Nav;
