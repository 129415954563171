import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import * as O from 'fp-ts/Option';
import * as EI from 'fp-ts/Either';
import { constVoid, pipe } from 'fp-ts/function';
import { ApplicationSpace } from '../../shared/utils/config';
import { BackProfile } from '../back/auth/model';
import { OperatorProfile } from '../operator/auth/model';

import * as BackAuthService from '../back/auth/service';
import * as OperatorAuthService from '../operator/auth/service';
import * as GroupAuthService from '../group/auth/service';

import { HttpTask } from '../../core/http';
import { useHistory } from 'react-router-dom';
import LineLoader from '../../layout/loaders/line-loader/LineLoader';
import { GroupProfile } from '../group/auth/model';

const AUTH_LOGOUT_KEY = 'QS_LOGOUT';

const getProfileTask: { [key in ApplicationSpace]: () => HttpTask<AuthContextProfile> } = {
  [ApplicationSpace.Back]: BackAuthService.getProfileAsAuthContextProfile,
  [ApplicationSpace.Operator]: OperatorAuthService.getProfileAsAuthContextProfile,
  [ApplicationSpace.Group]: GroupAuthService.getProfileAsAuthContextProfile,
};

const logoutTask: { [key in ApplicationSpace]: () => HttpTask<void> } = {
  [ApplicationSpace.Back]: BackAuthService.logout,
  [ApplicationSpace.Operator]: OperatorAuthService.logout,
  [ApplicationSpace.Group]: GroupAuthService.logout,
};

export type AuthContextBackProfile = BackProfile & {
  space: ApplicationSpace.Back;
};

export type AuthContextOperatorProfile = OperatorProfile & {
  space: ApplicationSpace.Operator;
};

export type AuthContextGroupProfile = GroupProfile & {
  space: ApplicationSpace.Group;
};

export type AuthContextProfile = AuthContextBackProfile | AuthContextOperatorProfile | AuthContextGroupProfile;

export interface AuthContextValue {
  profile: O.Option<AuthContextProfile>;
  updateProfile: (profile: AuthContextProfile) => void;
  requestUpdateProfile: () => void;
  handleLogout: () => void;
}

export const AuthContext = createContext<AuthContextValue>({
  profile: O.none,
  updateProfile: constVoid,
  requestUpdateProfile: constVoid,
  handleLogout: constVoid,
});

interface AuthContextProviderProps {
  space: ApplicationSpace;
}

export const AuthContextProvider: FC<PropsWithChildren<AuthContextProviderProps>> = ({ space, children }) => {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);

  const [profile, setProfile] = useState<O.Option<AuthContextProfile>>(O.none);

  const fetchProfile = useCallback(() => {
    getProfileTask[space]()().then(res => {
      setProfile(O.fromEither(res));
      setLoading(false);
    });
  }, [space]);

  useEffect(() => {
    fetchProfile();
  }, [fetchProfile]);

  useEffect(() => {
    const syncLogout = (event: StorageEvent) => {
      if (event.key === AUTH_LOGOUT_KEY) {
        setProfile(O.none);
        history.push('/login');
      }
    };

    window.addEventListener('storage', syncLogout);

    return () => {
      window.removeEventListener('storage', syncLogout);
      localStorage.removeItem(AUTH_LOGOUT_KEY);
    };
  }, [setProfile, history]);

  const updateProfile = useCallback((profile: AuthContextProfile) => setProfile(O.some(profile)), []);

  const handleLogout = useCallback(() => {
    logoutTask[space]()().then(res => {
      if (EI.isLeft(res)) {
        res.left.log();
      }

      setProfile(O.none);
      localStorage.setItem(AUTH_LOGOUT_KEY, Date.now().toString());

      history.push('/login');
    });
  }, [history, space]);

  const ctx: AuthContextValue = {
    profile,
    updateProfile,
    requestUpdateProfile: fetchProfile,
    handleLogout: handleLogout,
  };

  return <AuthContext.Provider value={ctx}>{loading ? <LineLoader /> : children}</AuthContext.Provider>;
};

export const useAuthContext = (): AuthContextValue => useContext(AuthContext);

export interface UseSpecificAuthContextValue<T> {
  profile: O.Option<T>;
  updateProfile: (profile: T) => void;
  requestUpdateProfile: () => void;
  handleLogout: () => void;
}

export function useBackAuthContext(): UseSpecificAuthContextValue<BackProfile> {
  const { profile, updateProfile, requestUpdateProfile, handleLogout } = useAuthContext();

  const backProfile = pipe(profile, O.filter(isAuthContextBackProfile));

  const handleUpdateProfile = useCallback(
    (profile: BackProfile) => updateProfile({ ...profile, space: ApplicationSpace.Back }),
    [updateProfile],
  );

  return {
    profile: backProfile,
    updateProfile: handleUpdateProfile,
    requestUpdateProfile,
    handleLogout,
  };
}

export function useOperatorAuthContext(): UseSpecificAuthContextValue<OperatorProfile> {
  const { profile, updateProfile, requestUpdateProfile, handleLogout } = useAuthContext();

  const operatorProfile = pipe(profile, O.filter(isAuthContextOperatorProfile));

  const handleUpdateProfile = useCallback(
    (profile: OperatorProfile) => updateProfile({ ...profile, space: ApplicationSpace.Operator }),
    [updateProfile],
  );

  return {
    profile: operatorProfile,
    updateProfile: handleUpdateProfile,
    requestUpdateProfile,
    handleLogout,
  };
}

export function useGroupAuthContext(): UseSpecificAuthContextValue<GroupProfile> {
  const { profile, updateProfile, requestUpdateProfile, handleLogout } = useAuthContext();

  const operatorProfile = pipe(profile, O.filter(isAuthContextGroupProfile));

  const handleUpdateProfile = useCallback(
    (profile: GroupProfile) => updateProfile({ ...profile, space: ApplicationSpace.Group }),
    [updateProfile],
  );

  return {
    profile: operatorProfile,
    updateProfile: handleUpdateProfile,
    requestUpdateProfile,
    handleLogout,
  };
}

function isAuthContextBackProfile(profile: AuthContextProfile): profile is AuthContextBackProfile {
  return ApplicationSpace.Back === profile.space;
}

function isAuthContextOperatorProfile(profile: AuthContextProfile): profile is AuthContextOperatorProfile {
  return ApplicationSpace.Operator === profile.space;
}

function isAuthContextGroupProfile(profile: AuthContextProfile): profile is AuthContextGroupProfile {
  return ApplicationSpace.Group === profile.space;
}
