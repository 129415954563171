import queryString, { StringifiableRecord } from 'query-string';
import { camelToSnake } from './string';
import { pipe } from 'fp-ts/function';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/string';

export function queriesToSnakeCase(queries: StringifiableRecord): StringifiableRecord {
  return pipe(
    queries,
    R.reduceWithIndex(S.Ord)({} as StringifiableRecord, (key, acc, value) => ({
      ...acc,
      [camelToSnake(key)]: value,
    })),
  );
}
export function stringifyQueries(queries: StringifiableRecord): string {
  return queryString.stringify(queriesToSnakeCase(queries), { skipEmptyString: true, skipNull: true });
}
