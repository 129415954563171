import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

export const camelToSnake = (value: string): string => {
  return value.replace(/[\w]([A-Z])/g, m => m[0] + '_' + m[1]).toLowerCase();
};

export const filterEmptyStringToOption = (value: string | null | undefined): O.Option<string> => {
  return pipe(
    O.fromNullable(value),
    O.filter(v => v !== ''),
  );
};

export const filterEmptyStringToNullable = (value: string | null | undefined): string | null => {
  return O.toNullable(filterEmptyStringToOption(value));
};

export function capitalizeFirstLetter(value: string): string {
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`;
}
