import { httpService, HttpTask } from '../../../core/http';
import {
  AuthenticateParams,
  ChangeEmailParams,
  ChangePasswordParams,
  ForgotPasswordParams,
  PasswordParams,
} from '../../auth/model';
import { GroupProfile, UpdateGroupProfileParams } from './model';
import { AuthContextProfile } from '../../auth/context';

import * as TE from 'fp-ts/TaskEither';
import { pipe } from 'fp-ts/function';
import { ApplicationSpace } from '../../../shared/utils/config';

export function getProfile(): HttpTask<GroupProfile> {
  return httpService.get('/api/group/profile');
}

export function getProfileAsAuthContextProfile(): HttpTask<AuthContextProfile> {
  return pipe(
    getProfile(),
    TE.map<GroupProfile, AuthContextProfile>(p => ({ ...p, space: ApplicationSpace.Group })),
  );
}

export function authenticate(params: AuthenticateParams): HttpTask<void> {
  return httpService.post('/api/group/authenticate', params);
}

export function logout(): HttpTask<void> {
  return httpService.post('/api/group/logout');
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask<void> {
  return httpService.post('/api/group/password-reset/forgot', params);
}

export function getResetPasswordDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/api/group/password-reset/${token}`);
}

export function resetPassword(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/api/group/password-reset/${token}`, params);
}

export function getActivationDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/api/group/activation/${token}`);
}

export function activateAccount(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/api/group/activation/${token}`, params);
}

export function updateProfile(params: UpdateGroupProfileParams): HttpTask<void> {
  return httpService.post('/api/group/profile', params);
}

export function updateProfilePassword(params: ChangePasswordParams): HttpTask<void> {
  return httpService.post('/api/group/profile/password', params);
}

export function updateProfileEmail(params: ChangeEmailParams): HttpTask<void> {
  return httpService.post('/api/group/profile/email', params);
}
