import React, { FC } from 'react';

import { RouteProps, Redirect, Route } from 'react-router-dom';

import { Routes, safeLazy } from '../../core/router';
import { useBackAuthContext } from '../auth/context';

import * as O from 'fp-ts/Option';

export interface BackRouteProps {
  restricted?: boolean;
}

export const BackRoute: FC<BackRouteProps & RouteProps> = ({ restricted, ...routeProps }) => {
  const { profile } = useBackAuthContext();

  if (restricted && O.isNone(profile)) {
    return <Redirect to={{ pathname: '/login', state: { referrer: window.location.pathname } }} />;
  }

  return <Route {...routeProps} />;
};

const BackAuthRoutes = safeLazy(() => import('./auth/routes'));

const BackOperatorsRoutes = safeLazy(() => import('./operators/routes'));
const BackGroupsRoutes = safeLazy(() => import('./groups/routes'));
const BackUsersRoutes = safeLazy(() => import('./users/routes'));
const BackErratumRoutes = safeLazy(() => import('./erratum/routes'));
const BackReportsRoutes = safeLazy(() => import('./reports/routes'));
const BackSyncsRoutes = safeLazy(() => import('./syncs/routes'));
const BackAuditorsRoutes = safeLazy(() => import('./auditors/routes'));
const BackInspectionsTrackingRoutes = safeLazy(() => import('./inspections-tracking/routes'));
const BackGridsRoutes = safeLazy(() => import('./grids/routes'));
const BackGridTemplatesRoutes = safeLazy(() => import('./grid-templates/routes'));
const BackGridDocumentsRoutes = safeLazy(() => import('./grid-documents'));

const BackRoutes: FC = () => (
  <Routes>
    <Redirect path="/" to="/grids" exact />
    <BackRoute path="/grid-templates" component={BackGridTemplatesRoutes} restricted />
    <BackRoute path="/grid-documents" component={BackGridDocumentsRoutes} restricted />
    <BackRoute path="/grids" component={BackGridsRoutes} restricted />
    <BackRoute path="/operators" component={BackOperatorsRoutes} restricted />
    <BackRoute path="/groups" component={BackGroupsRoutes} restricted />
    <BackRoute path="/users" component={BackUsersRoutes} restricted />
    <BackRoute path="/erratum" component={BackErratumRoutes} restricted />
    <BackRoute path="/reports" component={BackReportsRoutes} restricted />
    <BackRoute path="/syncs" component={BackSyncsRoutes} restricted />
    <BackRoute path="/auditors" component={BackAuditorsRoutes} restricted />
    <BackRoute path="/inspections-tracking" component={BackInspectionsTrackingRoutes} restricted />
    <BackRoute path="/" component={BackAuthRoutes} />
  </Routes>
);

export default BackRoutes;
