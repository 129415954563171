import React, { FC, useMemo } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useOperatorAuthContext } from '../auth/context';
import * as O from 'fp-ts/Option';
import { Routes, safeLazy } from '../../core/router';
import { OperatorProfileType } from './auth/model';
import { pipe } from 'fp-ts/function';
import { isOperatorAccountProfile } from './auth/utils';

export interface OperatorRouteProps {
  restricted?: true | OperatorProfileType;
}

export const OperatorRoute: FC<OperatorRouteProps & RouteProps> = ({ restricted, ...routeProps }) => {
  const { profile } = useOperatorAuthContext();

  const redirectUrl = pipe(
    profile,
    O.fold(
      () => (!!restricted ? O.some('/login') : O.none),
      p => (typeof restricted === 'string' && p.type !== restricted ? O.some('/') : O.none),
    ),
  );

  return pipe(
    redirectUrl,
    O.fold(
      () => <Route {...routeProps} />,
      url => <Redirect to={{ pathname: url, state: { referrer: window.location.pathname } }} />,
    ),
  );
};

const OperatorAuthRoutes = safeLazy(() => import('./auth/routes'));
const OperatorInspectionsRoutes = safeLazy(() => import('./inspections/routes'));
const OperatorDocumentsRoutes = safeLazy(() => import('./documents/routes'));

const OperatorRoutes: FC = () => {
  const { profile } = useOperatorAuthContext();

  const defaultRoute = useMemo(() => {
    return pipe(
      profile,
      O.fold(
        () => '/login',
        p => (isOperatorAccountProfile(p) ? '/inspections' : `/inspections/${p.inspectionId}`),
      ),
    );
  }, [profile]);

  return (
    <Routes>
      <Redirect path="/" to={defaultRoute} exact />
      <OperatorRoute path="/inspections" component={OperatorInspectionsRoutes} restricted />
      <OperatorRoute path="/documents" component={OperatorDocumentsRoutes} restricted={OperatorProfileType.Account} />
      <OperatorRoute path="/" component={OperatorAuthRoutes} />
    </Routes>
  );
};

export default OperatorRoutes;
